<template>
  <div id="research-mobile">
    <div id="home-mobiles" class="show-tabbar">
      <div class="home-header">
        <div class="virtual-input">
          <div :class="['virtual-scroll', anti_animate ? 'anti-animate' : '']" :style="{
            transform:
              'translateY(-' + (index / placeholders.length) * 100 + '%' + ')'
          }">
            <div v-for="(item, index_2) in placeholders" :key="index_2" class="virtual-scroll-item">
              <van-icon name="search" />
              <div class="placeholder">{{ item }}</div>
            </div>
        </div>
      </div>
        <van-icon class="quick-action" color="rgb(67, 122, 239)" name="add"  @click="showPublishDialog"/>
        <mobileEmail class="about-me"></mobileEmail>
    </div>
      <van-pull-refresh ref="refresh_ref" :class="[pullLoading ? 'loading' : refreshed ? 'refreshed' : '']"
        v-model="pullLoading" @refresh="onRefresh">

        <van-notify v-model="refreshed" type="success">
          <van-icon name="bell" style="margin-right: 4px;" />
          <span>更新成功</span>
        </van-notify>
        <div class="post-container">
          <Aticles  ref="aticleRef" :aticleList="posts" @getPostList="getPostList" @getMoreaticle="getMoreaticle"></Aticles>
        </div>
      </van-pull-refresh>

    </div>
  </div>
</template>

<script>
import { mobileEmail } from "../../../core/icon";
import { relativeTime } from '@/utils/util.js';
import { mapState } from 'vuex'
import Aticles from '@/components/Mobile/Aticles'
export default {
  name: "Research",
  components: {
    mobileEmail,
    Aticles,
  },
  data() {
    return {
      pullLoading: false,  //是否处于加载中状态
      refreshed: false, //是否更新
      // 分页对象
      pagination: {
        page: 1,
        limit: 20,
        page_count: 0
      },

      // 搜索关键词
      keyword: "",

      // 当前帖子列表
      posts: [],
      // 每一个帖子的id
      postId: 0,
      loading: false,

      placeholders: [
        "赵诣新基金首次开放申购",
        "ChatGPT概念还能炒多久",
        "市场回暖，私募成长VS价值策略怎么",
        "华为超融合战略发布在即，相关概念",
        "赵诣新基金首次开放申购"
      ],
      anti_animate: false,
      index: 0,
      // 每页帖子列表的最后一个帖子的创造时间
      last_create_time:''
    };
  },
  computed: {
    ...mapState(['token', 'userInfo']),
  },
  // 初始化
  activated() {
    this.loading = false;
    this.getPostList();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {

    // 获取帖子列表
    async getPostList() {
       // 优化bug，防止出现首次多加载一次的情况
      this.$refs.aticleRef.finished = false;
      try {
        let { data: res } = await this.api
          .postFormAPISM(
            {
              ...this.pagination,
              keywords: this.keyword || "",
              start_time: ""
            },
            "/article/getpostarticlelist"
          )
        this.posts = res.data.article_info_list;
         // 每页帖子列表的最后一个帖子的创造时间
       this.last_create_time = this.posts[this.posts.length-1].create_time;
        // 转化时间格式
        this.posts.forEach((item) => {
          item.create_time = relativeTime(item.create_time);
        });
        console.log(123);
      } catch (error) {
        console.log(error);
      }

    },
    // 上拉刷新
    async onRefresh() {
      this.loading = false;
      this.pagination = {
        ...this.$options.data().pagination,
      };
      this.pullLoading = true;
      this.refreshed = false;
      // 重新获取帖子列表
      await this.getPostList();
      // 弹出刷新提示
      this.refreshed = true;
      this.pullLoading = false;
      setTimeout(() => {
        //  // 关闭刷新提示
        this.refreshed = false;
      }, 1000)
    },
      // 加载更多，加载更多帖子内容
      async getMoreaticle() {
        // 如已经加载完成就不用继续下去
        if(this.$refs.aticleRef.finished) return;
         // 如果没有帖子最后一项的 time,则不继续下去
         if(this.last_create_time == '') return;
            try {
                let { data: res } = await this.api
                    .postFormAPISM(
                        {
                          limit:20,
                          keywords: this.keyword || "",
                            start_time: this.last_create_time
                        },
                        "/article/getpostarticlelist"
                    )
                let arrs = res.data.article_info_list;
                // 先判断数组是否为空
                if(arrs.length !==0)
                {
                     // 每页帖子列表的最后一个帖子的创造时间
                  this.last_create_time = arrs[arrs.length-1].create_time;
                }
        // 转化时间格式
        arrs.forEach((item) => {
          item.create_time = relativeTime(item.create_time);
        });
           this.posts = [...this.posts, ...arrs];
                // 判断是否所有的数据全部加载完成，如果是：finished设为true
                if (arrs.length === 0) {
                    // 说明取不到数据，加载完成，finish设置为true
                    this.$refs.aticleRef.finished = true;
                }
                console.log('数组长度',arrs.length);
            } catch (error) {
                console.log(error);
                // this.$refs.aticleRef.finished = false;
            }
        },
     
    // 显示发布长文对话框
    showPublishDialog() {
      this.$dialog.confirm({
        message: '是否发布长文',
      })
        .then(() => {
          // on confirm
          // 去发布长文页面
          this.$router.push('/publish');
        })
        .catch(() => {
          // on cancel
        });
    }


  }
}
</script>
<style lang="less" >
@import "./index.less";

#research-mobile {

  height: 100vh;
  margin-top: 20px;

  .post-container {
    overflow: hidden;

    .post-item {
      margin-top: 12px;
      padding: 12px 16px;
      background-color: white;

      &:first-child {
        margin-top: unset;
      }

      &:last-child {
        margin-bottom: 62px;
      }

      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        .top-bar-l {
          display: flex;
          align-items: center;

          .user-logo {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

          .user-name {
            font-size: 16px;
            margin-right: 10px;
          }

          .send-time {
            font-size: 14px;
            opacity: 0.6;
          }
        }

        .follow-btn {
          box-sizing: border-box;
          width: 70px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          // padding: 8px 16px;
          background-color: #f3f7fa;
          // border-radius: 18px;
          color: #27a1ff;
          border: 1px solid #f3f7fa;
          font-size: 14px;

          &.active {
            color: #ccc;
          }
        }
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 14px;
        line-height: 32px;
      }

      .content {
        display: -webkit-box;
        font-size: 14px;
        margin-bottom: 14px;
        line-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;

        & img {
          width: 100%;
        }

        &.parent-content {
          padding: 10px;
          background: #f5f5f5 !important;
          border-radius: 15px;
          box-sizing: border-box;

          &>.user-name {
            color: rgb(67, 122, 239);
          }

          & p {
            background: #f5f5f5 !important;
          }

          & span {
            background: #f5f5f5 !important;
          }

        }
      }

      .bot-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cross img {
          width: 10px;
          height: 10px;
        }

        .bot-bar-l {
          display: flex;
          align-items: center;

          div {
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
          }

          img {
            margin-right: 10px;
            width: 15px;
            height: 15px;
          }
        }
      }



    }
  }

  .van-pull-refresh {
    background-color: #f3f7fa;
    height: calc(100% - 28px);
    padding-bottom: 66px;

    &>.van-pull-refresh__track {
      overflow: auto;
      transform: unset !important;
      position: relative;

      &>.van-popup {
        position: fixed;
        width: 84px;
        padding: 4px 16px;
        border-radius: 50px;
        font-size: 12px;
        margin: 0 calc(50% - 50px);
        top: 20px;
        background-color: rgb(67, 122, 239);

        &>i {
          display: none;
        }
      }

      &>.van-pull-refresh__head {
        height: 0;
        display: block;
        position: unset;
        transform: unset;
        transition: height 0.4s ease-in-out;
      }
    }

    &.loading {
      & .van-pull-refresh__head {
        height: 50px;
      }
    }

    &.refreshed {
      & .van-pull-refresh__head {
        height: 0;
      }
    }
  }

  .message {
    &>.van-popup {
      position: fixed;
      width: 84px;
      padding: 4px 16px;
      border-radius: 50px;
      font-size: 12px;
      margin: 0 calc(50% - 50px);
      top: 40px;
      background-color: rgb(67, 122, 239);

      &>i {
        display: none;
      }
    }
  }

  .van-share-sheet__icon {
    width: 35px;
    height: 35px;
    margin: 0 20px;
  }
}
</style>
